@use 'styles/theme/theme' as theme;

.parent {
  display: flex;
  width: 100%;
  padding: 4px 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  &:hover {
    cursor: pointer;
    .arrowIcon {
      visibility: visible;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
    }
  }

  &.active {
    border-top: 2px solid theme.$blue;
    border-bottom: 2px solid theme.$blue;
    background: theme.$blue3;
    cursor: default;

    .arrowIcon {
      visibility: visible;
      display: flex;
      flex-direction: column;
      &.expandedLine {
        &::after {
          border-left: 1px solid theme.$secondaryGrey;
          content: '';
          height: 100%;
        }
      }
    }

    .activeIcon {
      fill: theme.$blue;
    }
  }
}

.components {
  display: flex;
  column-gap: 8px;
  width: calc(100% - 16px);

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: 40% 60%;
  }
}

.leftBlock,
.rightBlock {
  display: flex;
  column-gap: 8px;
}

.rightBlock {
  flex: 1;
}

.arrowIcon {
  visibility: hidden;
  width: 16px;
  &_right {
    visibility: visible;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .parent {
    flex-direction: row-reverse;
  }

  .components {
    width: 100%;
    column-gap: 16px;
  }

  .leftBlock,
  .rightBlock {
    column-gap: 16px;
  }

  .leftBlock {
    justify-content: flex-end;
    padding-right: 5px;
  }

  .rightBlock {
    flex-direction: column;
    align-items: center;
  }

  .arrowIcon {
    width: 16px;
    visibility: visible;
    align-self: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  .rightBlock {
    align-items: flex-start;
  }
}

.quickPreview {
  max-height: 80%;
}
