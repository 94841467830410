@import 'styles/theme/theme';

.drawerContainer {
  min-height: 80%;
}

.onboarding {
  width: 445px !important;
  height: 570px;
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }

  &__modalRoot {
    padding: 100px;
  }

  &__header {
    padding: 16px;
    border-bottom: 1px solid $borderGrey;

    &_logo {
      width: 20px;
      height: 16px;
    }
  }

  &__container {
    padding: 16px;
    overflow: scroll;
    height: 445px;

    @media screen and (max-width: 600px) {
      height: max-content;
    }
  }

  &__titleSection {
    display: flex;
  
    &_icon {
      margin-right: 8px;
    }
  }

  &__bold {
    font-weight: bold;
  }

  &__title {
    font-size: 22px;
    margin-bottom: 16px;

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  
  &__featureImage {
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      width: 260px;
      margin-bottom: 90px;
      position: unset;
    }
  }

  &__featuredArtists {
    margin-top: 20px;

    @media screen and (max-width: 600px) {
      margin-bottom: 90px;
    }
  }

  &__imageContainer {
    margin-top: 10px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    &_actions {
      border-top: 1px solid $borderGrey;
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      background: $white;

      @media screen and (max-width: 600px) {
        position: fixed;
        width: 100%;
        bottom: 0;
      }
    }

    &_backBtn {
      width: 116px;
      color: $primaryGrey;
    }
  
    &_nextBtn {
      width: 299px;
      margin-left: 16px;
  
      @media screen and (max-width: 600px) {
        width: 240px;
      }
    }

    &_fullWidthBtn {
      width: 100%;
      margin-left: 0;
    }
  }
}