.referencesContainer {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  align-items: center;
  .separator {
    margin-right: 4px;
  }
}

.references {
  display: flex;
  flex-wrap: wrap;
  margin: unset;
  padding: unset;
  list-style-type: none;
}

.references a {
  word-break: break-all;
  white-space: normal;
}
