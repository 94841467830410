@import 'styles/theme/theme';

.control {
  margin-bottom: 15px;
  @media (max-width: 600px) {
    max-width: 100%;
  }
  :global {
    & .MuiOutlinedInput-root {
      border-radius: 8px;
      &.Mui-focused fieldset {
        border-color: $blueChip;
      }
    }

    & label.Mui-focused {
      color: $blueChip
    }
  }
}

.hideBorder {
  :global {
    & fieldset {
      border: 0;
    }
  }
}

.label {
  background-color: transparent !important;
}

.paper {
  max-height: 250px;
  display: block;
  width: 240px;
  &__isLoading {
    display: none;
  }
}

.select {
  width: 100%;
  max-width: 340px;
  min-width: 340px;
  @media (max-width: 600px) {
    max-width: 100%;
  }
}
.empty {
  display: none;
}

.menuItem {
  white-space: unset;
  word-break: break-all;
}
