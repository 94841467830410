@import 'styles/theme/theme';

.root {
  @include container($tabPadding: 0, $mobilePadding: 0);
}

.page {
  & + & {
    margin-top: 40px;
  }
}

.content {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px 5px;

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 1000px) {
    width: 343px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.skeletonLoaders {
  display: flex;
  gap: 5px;
}
