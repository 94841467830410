@use 'styles/theme/theme' as theme;

.contentContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  row-gap: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.workName {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.workTypeTag {
  padding: 5px 6px;
  border-radius: 1px;
  border: 0.75px solid theme.$tertiaryGrey;
  margin-left: auto;
  font-size: theme.$font-size-11;
  color: theme.$primaryGrey;
  text-transform: uppercase;
  font-weight: theme.$font-weight-bold;
}

.card {
  border-radius: 4px;
  padding: 12px;
  border: 1px solid theme.$borderGrey;
  cursor: pointer;
  border-left: 2px solid theme.$borderGrey;
  min-width: 320px;
  width: 320px;
  height: 106px;
  max-height: 106px;

  &.disableClick {
    pointer-events: none;
  }

  &:hover {
    border: 1px solid theme.$blue;
    border-left: 2px solid theme.$blue;
    .icon {
      fill: theme.$blue;
    }
    .link {
      & > span {
        color: theme.$blue;
        text-decoration: underline;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    &_details {
      width: 100%;
      padding-right: 12px;
    }
  }

  &__performances {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 6px;
  }

  .separator {
    &::before {
      margin: 0 4px;
      width: 1px;
      height: 100%;
      content: '|';
    }
  }

  .professionInfo {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    &__validationStatus {
      margin-left: -4px;
    }
    &__icon {
      position: absolute;
      right: -4px;
    }
  }

  .link {
    font-size: theme.$font-size-12;
    font-weight: theme.$font-weight-regular;
    color: theme.$primaryGrey;
    &:hover {
      span:not(.count) {
        text-decoration: underline;
        color: theme.$blue;
      }
    }
   }

  .count {
    &:before {
      content: "•";
    }
    margin: 0 4px;
  }
}

.scroller {
  display: flex;
  width: 100%;
  column-gap: 12px;
  &__grid {
    flex-wrap: wrap;
    gap: 20px;
    .card {
      min-width: 413px;
      width: 413px;
    }
  }
}

.seeAllBtn {
  margin-top: 20px;
}

@media screen and (max-width: 1280px) {
  .scroller {
    &__grid {
      gap: 12px;
      .card {
        min-width: unset;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .card {
    min-width: 280px;
    width: 280px;
  }
}
