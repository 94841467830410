@use 'styles/theme/theme' as theme;

.parent {
  & > :not(:first-child) {
    margin-top: 8px;
  }
}

.collapsedSection {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.musicalWork {
  display: flex;
  flex-direction: column;
  width: 180px;
  align-items: flex-start;
  white-space: break-spaces;
  &.graphMWork {
    width: 150px;
  }
}

.musicalWork > span {
  display: inline-flex;
}

.artistRole {
  margin-right: 15px;
  @media only screen and (max-width: 1024px) {
    margin-right: 0;
  }
}
.composer {
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .musicalWork {
    width: 140px;
  }
}

@media only screen and (max-width: 850px) {
  .musicalWork {
    width: 100px;
  }
}

@media only screen and (max-width: 800px) {
  .collapsedSection {
    flex-direction: column-reverse;
    width: auto;
    gap: 4px;
    align-items: flex-end;
  }

  .musicalWork {
    width: 100%;
    text-align: right;
    &.graphMWork {
      width: 140px;
    }
  }
}
