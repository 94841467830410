@use 'styles/theme/theme' as theme;

.organizations {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.organization {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid theme.$borderGrey;
  padding: 10px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    .rightIcon {
      visibility: visible;
      fill: theme.$blue;
    }
  }
}

.rightIcon {
  visibility: hidden;
}
