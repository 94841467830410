@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media all and (max-width: 600px) {
  .root {
    gap: 10px;
  }
}

.artistCard {
  overflow: hidden;
  width: 100px;
  height: 148px;
  position: relative;

  &__details {
    position: absolute;
    bottom: 0;
    background: rgba(theme.$black, 0.7);
    padding: 4px;
    width: 100%;
    &_name {
      color: theme.$white;
      font-size: 11px;
      font-weight: bold;
    }
    &_profession {
      color: theme.$white;
      font-size: 9px;
      font-style: italic;
    }
  }


  &.large {
    width: 175px;
    height: 259px;

    .artistCard__details {
      padding: 10px;
      &_name {
        font-size: 14px;
      }
      &_profession {
        font-size: 12px;
      }
    }
  }
}

.moreCard {
  height: 100%;
  width: 100%;
  border: 1px solid theme.$borderGrey;
  display: flex;
  justify-content: center;
  align-items: center;
}


.featuredArtists {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
}