@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  flex-direction: column;
  grid-gap: 28px;
}

.viewAllBtn {
  &:hover {
    color: theme.$blue;
    svg {
      fill: theme.$blue;
    }
  }
}
