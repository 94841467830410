@use 'styles/theme/theme' as theme;

.footer {
  margin-top: 20px;
}

.header,
.footer {
  @include theme.container($tabPadding: 16px, $mobilePadding: 16px);
}

.root {
  @include theme.container($tabPadding: 0, $mobilePadding: 0);
  display: flex;
  align-items: stretch;

  &.withHeader {
    margin-top: 20px;
    @media screen and (max-width: 600px) {
      margin-top: 0px;
    }
  }

  &__filters {
    width: 210px;
    border-right: 1px solid theme.$tertiaryGrey;
    padding-top: 6px;
    padding-right: 30px;
    padding-bottom: 30px;
    position: relative;
    z-index: 3;
    @media only screen and (max-width: 1280px) {
      padding-left: 16px;
      padding-right: 16px;
    }
    @media screen and (max-width: 600px) {
      padding-top: 0;
      padding-bottom: 16px;
    }

    & ~ .root__content {
      width: calc(100% - 210px);
      padding-left: 30px;
    }
  }

  &__content {
    width: 100%;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 1280px) {
    &__filters {
      width: 196px;
      padding-right: 15px;
      border-right: none;
      & ~ .root__content {
        width: calc(100% - 196px);
        padding-left: 15px;
      }
    }
    &__content {
      width: 100%;
      padding-left: 0;
    }
  }

  @media screen and (max-width: 600px) {
    &__filters {
      padding-right: 0px;
    }
  }
}

.tabHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  @media screen and (max-width: 600px) {
    margin-right: 0px;
    margin-bottom: 16px;
    padding-left: 16px;
  }
  .verifiedText {
    margin-top: 8px;
    padding-left: 8px;
    text-align: right;
    white-space: nowrap;
    p + p {
      margin-top: 4px;
    }
    .icon {
      margin-right: 2px;
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    box-shadow: -2px 7px 11px -6px rgba(0, 0, 0, 0.3);
    background: linear-gradient(180deg, #fff -21.86%, #fff 68.51%);
    padding-bottom: 14px;
  }
}

.navigation {
  width: 100%;
  overflow: hidden;
  &__tab {
    margin: 6px 0 0 0;
    min-width: fit-content;
    & + & {
      margin-left: 8px;
    }
  }

  @media only screen and (max-width: 1280px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.tabFilters {
  margin-bottom: 16px;
}

@media screen and (max-width: 800px) {
  .root {
    flex-direction: column;
    &__filters {
      width: 100%;
      & ~ .root__content {
        width: 100%;
        padding-left: 0;
      }
    }

    &__content {
      width: 100%;
      padding-left: 0;
    }
  }
}

.scrollLeftIndicator {
  background: linear-gradient(90deg, #FFF -21.86%, #FFF 68.51%);
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.30);
  border-right: transparent;
}
.scrollRightIndicator {
  background: linear-gradient(90deg, #FFF -21.86%, #FFF 68.51%);
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.30);
  border-left: transparent;
  padding-right: 8px;
}
