.parent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: wrap;
  width: 97px;
  align-items: flex-end;
  .tag {
    display: flex;
    align-items: flex-end;
    text-align: right;
  }

  @media (max-width: 1024px) {
    width: 60px;
  }

  &.worksParent {
    align-items: flex-end;
    width: 60px;
  };
}

