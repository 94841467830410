@use 'styles/theme/theme' as theme;

.group {
  display: flex;
  grid-gap: 16px;
  & + & {
    margin-top: 32px;
  }
}

.fullWidthGroup {
  flex-direction: column;
}

.image {
  cursor: pointer;
}

.fullWidthCard {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .group {
    .card {
      width: auto;
    }
    .fullWidthCard {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
    }
  }
}

.card {
  position: relative;
}

.mediaAttribution {
  position: absolute;
  bottom: 0;
  right: 0;

  &.withProductionInfo {
    bottom: 48px;
    padding: 0px 16px 0px 16px;
  }
}
