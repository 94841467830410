@use 'styles/theme/theme' as theme;

.card {
  cursor: pointer;
  border: 1px solid theme.$tertiaryGrey;
  overflow: hidden;
  width: 305px;
  background: theme.$black;
  height: 170px;
  position: relative;

  &.fullWidth {
    height: auto;
    aspect-ratio: 305 / 170;
    width: 100%;

    @media screen and (max-width: 600px) {
      width: calc(100% + 32px);
      margin: 0 -16px;
    }
  }

  .overlay {
    background-color: rgba(theme.$black, 0.6);
  }

  &:hover {
    border: 1px solid theme.$blue;

    .overlay {
      background-color: rgba(theme.$black, 0);
    }
  }
}

.mediaAttribution {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  &.withProductionInfo {
    bottom: 48px;
    padding: 0px 16px 0px 16px;
  }
}