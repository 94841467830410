@use 'styles/theme/theme' as theme;

.root {
  @include theme.container($tabPadding: 0, $mobilePadding: 0);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: 0;
  align-items: center;
}

.floatingHeader {
  display: block;
  width: 100%;
  background: theme.$white;
  margin: auto;
  padding: 14px 0 6px 0;
  order: 3;
  z-index: 110;
  &__enableShadow {
    box-shadow: -2px 7px 11px -6px rgba(0, 0, 0, 0.3);
    background: linear-gradient(180deg, #fff -21.86%, #fff 68.51%);
  }
  &__isSticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    @media screen and (max-width: 1280px) {
      order: 5;
    }
  }
  &__withoutNavigation {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 1280px) {
    padding: 14px 16px 0px 16px;
    &__isNonDesktopOverviewPage {
      position: fixed;
      top: 0;
      &_addPadding {
        padding-bottom: 12px;
      }
    }
    &__separator {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 14px 16px 0px 16px;
    &__isNonDesktopOverviewPage {
      &_addPadding {
        padding-bottom: 12px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    padding-top: 0px;
    &__isSticky {
      padding-top: 10px;
    }
  }
}

.entitySuggestionsInline {
  margin-top: 22px;
  width: 100%;
  max-width: 1280px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 8px;
  max-width: 1280px;
  width: 100%;
  div {
    &:first-child {
      padding: 0;
    }
  }
  ul {
    margin: 0;
  }

  &__castingToolPromo {
    display: none;
  }
}

.navigation {
  opacity: 0;
  height: 0;
}

.stickyNavigation {
  margin-top: 22px;
  transition: margin-top 0.3s ease;
  opacity: 1;
  height: 100%;
  &__sticky {
    margin-top: 12px;
  }
}

.mediaWidget {
  max-width: 1280px;
  width: 100%;
  order: 3;
}

.biography,
.castingToolPromo,
.contactActions,
.exploreContainer,
.actions {
  display: none;
}

.tabContent {
  margin-top: 24px;
  order: 7;
  max-width: 1280px;
  width: 100%;
}

.entitySuggestionsFooter {
  margin-top: 16px;
  padding-top: 30px;
  border-top: 1px solid theme.$secondaryGrey;
}

.trustedByBlock {
  overflow-x: hidden;
  margin-top: 40px;
  margin-bottom: 16px;
}

.entityHeader {
  max-width: 1280px;
  margin: auto;
}

.profileInsights {
  order: 2;
  padding: 0 0px;
}

.root .socialLinks {
  order: 7;
  max-width: 1280px;
  width: 100%;
  padding: 0 16px;
}

.featuredReviews {
  order: 6;
  max-width: 1280px;
  margin-top: 32px;
  margin-bottom: 16px;
  width: 100%;
  @media all and (max-width: 1280px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@media all and (max-width: 1280px) {
  .entitySuggestionsInline {
    margin: 8px 0;
    order: 1;
    &__isOverview {
      order: 2;
    }
  }

  .profileInsights {
    order: 2;
    padding: 0;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .root .socialLinks {
    margin-top: 32px;
    &__previewModal {
      margin-bottom: 16px;
    }
  }

  .root .socialLinks,
  .featuredReviews {
    order: 6;
    padding: 0 16px;
  }

  .header {
    order: 3;
    &__isOverview {
      order: 1;
    }
  }

  .mediaWidget {
    width: 100%;
  }

  .breadcrumbs {
    display: flex;
    padding: 0 16px;
    margin: 0;
    order: 2;
    visibility: hidden;
    height: 0;
    &__withoutMediaWidget {
      visibility: visible;
      height: 100%;
      margin: 8px 0 0;
    }
    &__castingToolPromo {
      display: block;
      margin-left: 8px;
    }
  }

  .entityHeader {
    padding: 0px;
    &__hidden {
      display: none;
    }
  }

  .navigation {
    display: block;
    margin-top: 4px;
    padding: 0 16px;
    order: 9;
    background: linear-gradient(180deg, #fff -21.86%, #fff 68.51%);
    box-shadow: -2px 7px 11px -6px rgba(0, 0, 0, 0.3);
    width: 100%;
    opacity: 1;
    height: 100%;
    &__sticky {
      box-shadow: none;
    }
    &__isPreviewMode {
      box-shadow: none;
    }
  }

  .stickyNavigation {
    &__sticky {
      margin-top: 12px;
      height: 100%;
      padding: 0;
      opacity: 1;
      transition: none;
    }
    &__hide {
      height: 0;
      opacity: 0;
      margin: 0;
      pointer-events: none;
    }
  }

  .biography {
    display: block;
    padding: 0 16px;
    margin-left: 143px;
    margin-top: 10px;
    order: 4;

    &__withoutMediaWidget {
      order: 5;
      margin-left: 0;
      width: 100%;
    }
  }

  .contactActions {
    display: block;
    padding: 0 16px;
    margin-top: 18px;
    width: 100%;
    order: 5;

    &__contacts {
      margin-bottom: 16px;
    }

    &__withMediaWidget {
      margin-top: 60px;
    }
  }

  .actions {
    display: block;
    width: 100%;
    order: 6;
    margin-bottom: 12px;
    padding: 0 16px;
  }

  .castingToolPromo {
    display: block;
    padding: 0 16px;
    margin: 12px 0;
    width: 100%;
    order: 6;
  }

  .tabContent {
    width: 100%;
    order: 9;
  }

  .trustedByBlock {
    padding: 0 16px;
  }

  .previewModeFloatingHeader {
    top: 53px;
    visibility: hidden;
    &__visible {
      visibility: visible;
    }
  }

  .root .entitySuggestionsFooter {
    padding-left: 16px; 
    &__horizontalScroll {
      margin-left: 0;
      width: calc(100% - 16px);
      max-width: calc(100% - 16px);
    }
  }
}

@media all and (max-width: 1024px) {
  .castingToolPromo {
    padding: 0 8px;
    margin: 0 0 8px 0;
  }
}

@media all and (max-width: 600px) {
  .profileInsights {
    padding: 0 16px;
  }

  .exploreContainer {
    display: block;
    padding: 0 16px;
    margin-bottom: 40px;
  }

  .featuredReviews {
    padding-left: 16px;
    padding-right: 0px;
  }

  .socialLinks {
    padding-left: 16px;
    padding-right: 16px;
  }

  .biography {
    margin-left: 85px;

    &__withoutMediaWidget {
      margin-left: 0;
    }
  }

  .castingToolPromo {
    padding: 0 16px;
    margin-bottom: 16px;
  }

  .navigation {
    background: transparent;
    box-shadow: none;
  }
}

.hideHeader {
  z-index: -1;
}

.drawer {
  max-height: 90%;
  bottom: 0;
  transform: translateY(100%);
  animation: slideUp 0.5s forwards ease-in-out;

  .headerText {
    margin-left: auto;
    max-width: 1264px;
  }
  .headerCloseIcon {
    margin-right: auto;
  }

  .drawerContent {
    & > * {
      pointer-events: none;
    }
  }
  @keyframes slideUp {
    to {
      transform: translateY(0);
    }
  }
}

.previewDrawerRoot {
  z-index: 100000;
}
