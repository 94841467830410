.group {
  display: grid;
  grid-gap: 16px;
  & + & {
    margin-top: 32px;
  }
}

.column {
  &_single {
    grid-template-columns: 343px;
    width: 343px;
  }
  &_double {
    grid-template-columns: 343px 343px;
    width: 686px;
  }
  &_triple {
    grid-template-columns: 343px 343px 343px;
    width: 1029px;
  }
  &_full {
    grid-template-columns: 1fr;
    width: 100%;
    & > div {
      max-width: none;
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 800px) {
    &_double {
      grid-template-columns: 343px 343px;
      width: 343px;
    }
  }
  @media screen and (max-width: 752px) {
    &_double {
      grid-template-columns: 270px;
      width: 270px;
    }
  }

  @media screen and (max-width: 600px) {
    &_single,
    &_double {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
}