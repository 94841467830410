@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.similarArtists {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
}

.horizontalScrollItems {
  > div {
    grid-gap: 8px;
    white-space: unset;
    align-items: unset;
  }
}

.title {
  @media screen and (max-width: 600px) {
    color: theme.$white;
  }
}

.seeAllLink {
  @media screen and (max-width: 600px) {
    color: theme.$white;
    svg {
      fill: theme.$white !important;
    }
  }
}

.leftBoxIcon {
  @media only screen and (max-width: 600px) {
    &::before {
      background-color: theme.$white;
    }
  }
}
