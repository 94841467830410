@use 'styles/theme/theme' as theme;

.root {
  & + & {
    margin-top: 40px;
    @media screen and (max-width: 600px) {
      margin-top: 24px;
    }
  }
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &_content {
    width: fit-content;
  }
  &_actions {
    padding-left: 12px;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
  }

  .mobileCtaLink {
    display: none;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;

    &_content {
      width: 100%;
    }
    &_actions {
      width: 100%;
      // margin-top: 12px;
      padding-left: 0;
    }

    .ticketLink {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .main {
    &_content {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .main {
    position: relative;
  }
  .ctaLink {
    display: none;
  }

  .mobileCtaLink {
    display: flex !important;
    background-color: theme.$primaryGrey;
    border: 1px solid transparent;
    padding: 8px 12px;
    color: theme.$white;
    position: absolute;
    top: 16px;
    right: 0px;
    svg {
      fill: theme.$white !important;
    }
  }
}
