@use 'styles/theme/theme' as theme;

.collapsedSection {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  width: calc(100% - 445px);
  flex: 1;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  &__director,
  &__conductor {
    width: 121px;
    display: flex;
    .names {
      width: 100%;
      display: block;
      word-break: break-word;
      white-space: break-spaces;
    }
  }

  &__cast {
    width: 248px;
    display: flex;
    &.graphCast {
      width: 220px;
    }
  }
}

.coverRole {
  padding-left: 5px;
}

.expandedSection {
  display: flex;
  column-gap: 16px;
  width: 100%;
  margin-top: 1px;
  &__crew {
    width: 238px;
    flex-direction: column;
    gap: 8px;
    display: flex;
    align-items: center;
    &_details {
      .primaryCastCrew {
        width: 50%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        .name {
          font-size: theme.$font-size-12;
          font-weight: theme.$font-weight-regular;
        }
      }
    }
    &.crewSection {
      width: 225px;
    }
  }

  &__cast {
    min-width: calc(100% - 238px);
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.castSection {
      width: 210px;
    }
  }

  &__crew,
  &__cast {
    &_details {
      display: flex;
      gap: 10px;
      justify-content: center;
      width: 100%;
      .list {
        display: flex;
        flex-direction: column;
        width: 50%;
        .name {
          display: flex;
          align-items: flex-start;
          flex-flow: row wrap;
          justify-content: left;
          white-space: break-spaces;
          flex: 1;
          font-size: theme.$font-size-12;
          font-weight: theme.$font-weight-regular;
          &__isBold {
            font-weight: theme.$font-weight-bold;
          }
        }
      }
      .roleType {
        width: 50%;
        display: flex;
        flex-flow: row wrap;
        justify-content: right;
        text-align: right;
        white-space: break-spaces;
        align-items: flex-start;
      }
    }
  }

  &__tilda {
    text-align: center;
  }
}

@media only screen and (max-width: 1280px) {
  .expandedSection {
    flex-direction: column;
    align-items: center;
    &__crew {
      align-items: center;
      border-bottom: 1px solid theme.$secondaryGrey;
      padding-bottom: 8px;
    }

    &__cast {
      align-items: center;
      padding-top: 8px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .expandedSection {
    flex: 1;
    width: 214px;
    &__crew,
    &__cast {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .expandedSection {
    &__crew,
    &__cast {
      min-width: 130px;
    }
  }

  .collapsedSection {
    flex-direction: column;
    gap: 4px;
    &__director,
    &__conductor {
      min-width: 130px;
    }

    &__cast {
      min-width: 130px;
      align-items: flex-start;
    }
  }
}

@media only screen and (max-width: 900px) {
  .expandedSection {
    &__crew,
    &__cast {
      min-width: 115px;
    }
  }

  .collapsedSection {
    flex-direction: column;
    gap: 4px;
    &__director,
    &__conductor {
      min-width: 100%;
    }

    &__cast {
      min-width: 115px;
      align-items: flex-start;
    }
  }
}
