@use 'styles/theme/theme' as theme;

.rentalCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 343px;

  &:hover {
    .companyName, .cast, .costumeBy, .text {
      color: theme.$primaryGrey;
    }
    .firstLine {
      .date {
        color: theme.$primaryGrey;
      }
      .viewContact {
        color: theme.$blue;
        .arrowIcon {
          fill: theme.$blue;
        }
      }
    }
  }

  .companyName, .cast, .costumeBy, .text {
    display: inline-block;
    text-align: left;
    width: 100%;
    color: theme.$secondaryGrey;
  }

  .firstLine {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .date {
      display: inline-block;
      text-align: left;
      width: 100%;
      color: theme.$secondaryGrey;
    }
    .viewContact {
      display: inline-block;
    }
  }

  .title {
    display: flex;
    width: 100%;
    align-items: center;
    .productionName {
      width: fit-content;
    }
    .composer {
      margin-left: 4px;
      margin-top: 2px;
      width: fit-content;
    }
  }
}

.imageWrapper {
  background-color: white;
  margin-bottom: 8px;
  height: 194px;
  overflow: hidden;
  width: 343px;
}

.section {
    margin-top: 8px;
}

@media screen and (max-width: 600px) {
  .impressionCard,
  .rentalCard {
    width: 100%;
  }
  
  .imageWrapper {
    height: auto;
    aspect-ratio: 305 / 170;
    width: calc(100% + 32px);
    margin: 0 -16px;
   }
}
