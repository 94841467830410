@use 'styles/theme/theme' as theme;

.parent {
  align-self: center;
  height: 100%;
  & > :not(:first-child) {
    margin-top: 8px;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
    height: auto;
    margin-bottom: 4px;
  }
}

.collapsedSection {
  width: 140px;
  max-width: 140px;
  display: flex;
  align-items: flex-start;
  &__multiRole {
    flex-direction: column;
    gap: 0px;
  }
  @media only screen and (max-width: 600px) {
    width: auto;
    max-width: fit-content;
  }
}

.expandedSection {
  width: 140px;
  max-width: 140px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__coPartner {
    display: flex;
    column-gap: 4px;
    align-items: flex-start;
    &__name {
      font-size: 12px;
      white-space: break-spaces;
      font-weight: theme.$font-weight-regular;
    }
  }
  &__ensemble {
    display: flex;
    column-gap: 4px;
    align-items: flex-start;
    .type {
      display: flex;
      white-space: break-spaces;
      justify-content: flex-end;
    }
    .name {
      font-size: 12px !important;
      white-space: break-spaces;
      font-weight: theme.$font-weight-regular;
    }
  }
  
}

.link {
  width: 100%;
  text-align: left;
  .primaryProducer {
    display: block;
    width: 100%;
    word-break: break-word;
    white-space: break-spaces;
    letter-spacing: normal;
  }
  &.hover {
    &:hover {
      text-decoration: underline;
      span {
        color: theme.$blue;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .venue {
    display: none;
  }
}
