@use 'styles/theme/theme' as theme;

.langDropdownLarge {
  margin-left: 24px;
}

.bioSection {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.profileImage {
  border: 1px solid theme.$borderGrey;
  width: 136px;
  height: 200px;
  @media (max-width: 600px) {
    width: 85px;
    height: 120px;
  }
}

.bioWrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 40px;

  @media (max-width: 1024px) {
    column-gap: 20px;
  }

  @media (max-width: 600px) {
    column-gap: 10px;
  }

  .bioText {
    white-space: pre-wrap;
    width: 100%;
  }
}

.singleLanguage {
  margin-left: 12px;

  &__name {
    margin-left: 4px;
    color: theme.$blue;
  }
}

.about {
  color: theme.$secondaryGrey;
  font-weight: theme.$font-weight-regular;
  white-space: pre-wrap;
  text-align: start;
  display: block;
  border-radius: 0px;
  .bioText {
    width: 100%;
  }
  &:hover {
    text-decoration: none;
    color: theme.$primaryGrey;
    .readMoreLink {
      color: theme.$blue;
      @media (max-width: 600px) {
        color: theme.$primaryGrey;
      }
    }
  }
  .clampedContent {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    @media (max-width: 600px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 450px) {
      -webkit-line-clamp: 5;
    }
    .readMoreLink {
      position: absolute;
      bottom: 0;
      right: 0;
      background: white;
      padding-left: 5px;
    }
  }
}

.mobileSection {
  display: none;

  @media (max-width: 600px) {
    display: block;
    padding: 0 16px;

    &__wrapper {
      display: flex;
      align-items: flex-start;
      & > button {
        width: 80%;
      }
      &_logo {
        margin-right: 12px;
        border: 1px solid theme.$tertiaryGrey;
      }
      &_content {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        width: 100%;
        overflow: hidden;
      }
    }
  }
}

.langDropdownLarge {
  margin-left: 8px;
  display: flex;
  align-items: baseline;
}
.formControl {
  padding-bottom: 0;
  width: max-content;
  margin-bottom: 0 !important;
}

.select {
  min-width: min-content !important;
  max-width: max-content !important;
  max-height: 42px;
  & > div {
    padding: 0px 5px;
    color: theme.$blue;
    font-size: 12px;
  }
}

.stageSection {
  max-width: 400px;
}

.stageList {
  padding: unset;
  margin: unset;
  list-style: none;
}

.stageItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
