.root {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.card {
  width: 165px;
  height: 328px;
  cursor: pointer;

  &_poster {
    width: 165px;
    height: 244px;
    position: relative;
  }
  &_logo {
    position: absolute;
    top: 12px;
    left: 12px;
  }
  &_season {
    position: absolute;
    top: 8px;
    right: 8px;
    text-align: right;
    background: rgba(0, 0, 0, 0.6);
    padding: 4px;
  }
  &_payment {
    text-transform: uppercase;
  }
  &_details {
    padding-top: 8px;
  }
  &_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: 50px 8px 12px;
    flex-direction: column;
    justify-content: space-between;
    display: none;
  }
  &_info {
    background: rgba(0, 0, 0, 0.6);
    display: inline-block;
    padding: 4px;
  }
  &_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 4px;
  }

  &:hover {
    .card_overlay {
      display: flex;
    }
  }
}

.cardDetailsLink {
  display: block;
}

.redMaskIcon {
  margin-left: -4px;
}

@media only screen and (max-width: 1024px) {
  .card {
    &_overlay {
      display: flex;
    }
  }
}
